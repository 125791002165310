import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { logoutUser } from '../../redux/actions';

function Logout() {
  const { logout } = useAuth0();
  logout({ returnTo: 'https://oiloginhml.oi.net.br/nidp/app/logout' });

  return <React.Fragment />;
}

export default withRouter(connect(null, { logoutUser })(Logout));
